@tailwind base;
@tailwind components;
@tailwind utilities;

.project {
  padding-top: 1rem;
}

.project-content {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}

@media (max-width: 800px) {
  .project-content {
    text-align: center;
  }
}

.project-content p {
  color: #147efb;
  text-transform: uppercase;
  font-weight: 700;
  /* margin-bottom: 1rem; */
}

@media (max-width: 600px) {
  .project-content p {
    margin-bottom: 0rem;
  }
}

.project-content h3 {
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

@media (max-width: 600px) {
  .project-content h3 {
    font-size: 24px;
  }
}

.projects-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* grid-template-columns: 1fr; */
  /* grid-template-rows: auto auto; */
  /* width: 100%; */
  /* height: auto; */
  gap: 1rem;
}

@media (max-width: 600px) {
  .projects-grid {
    gap: 0rem;
  }
}

.projects-grid .pro {
  display: flex;
  width: 100%;
  /* background-color: white; */
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  border-radius: 1.7rem;
}

.projects-grid .pro__1 {
  height: fit-content;
  flex-direction: row;
  padding: 2rem;
  gap: 4rem;
}

.reversed-proj {
  flex-direction: row-reverse !important;
}

@media (max-width: 1020px) {
  .projects-grid .pro__1 {
    /* height: 45rem; */
    /* height: max-content; */
    /* max-height: 80px; */
    flex-direction: column !important;
    gap: 3rem;
    width: fit-content;
    margin: 0 auto;
  }
}

@media (max-width: 800px) {
  .projects-grid .pro__1 {
    /* height: 42rem; */
    /* height: max-content; */
    /* max-height: 80px;   */
    width: 100%;
    padding: 1rem;
    margin: 1rem;
  }
}

.projects-grid .pro__img {
  width: 30rem;
  height: auto;
  border-radius: 1.7rem;
  background-color: #76767633;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

@media (max-width: 650px) {
  .projects-grid .pro__img {
    width: 100%;
    height: 40%;
  }
}

.projects-grid .pro__img img {
  width: 100%;
  height: auto;
}

.projects-grid .pro__text {
  display: flex;
  width: 30rem;
  height: auto;
  flex-direction: column;
  text-align: center;
  /* justify-content: center; */
}

@media (min-width: 1300px) {
  .projects-grid .pro__text {
    gap: 1rem;
  }
}

@media (max-width: 1020px) {
  .projects-grid .pro__text {
    margin: 0 auto;
  }
}

@media (max-width: 600px) {
  .projects-grid .pro__text {
    width: 100%;
  }
}

.projects-grid .pro__text img {
  width: 7rem;
  position: absolute;
  top: -2px;
  right: -19px;
}

.projects-grid .pro__text h3 {
  /* font-size: 1.5rem; */
  font-weight: 900;
  text-transform: uppercase;
  /* color: #2d2e32; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}

.projects-grid .pro__text p {
  font-size: 1rem;
  /*  color: #2d2e32; */
  text-align: start;
  text-transform: none;
  font-weight: 500;
}

.stack {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.stack p {
  color: rgb(255, 255, 255) !important;
  font-weight: 600 !important;
  background-color: #147efb;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  padding: 0.2rem 1rem;
  font-family: Mulish, sans-serif !important;
  border-radius: 10px;
}

.links {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
  gap: 1rem;
  align-items: center;
}

@media (max-width: 600px) {
  .links {
    margin-top: 1.5rem;
  }
}

.links a {
  font-size: 1rem;
  color: #2d2e32;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.links a i {
  font-size: 2.8rem;
}

.links a .link-icon {
  font-size: 2rem !important;
}

.links a img {
  width: 3.5rem;
}

.links a:hover {
  color: #147efb;
  transition: all 0.3s;
}

/* === removing default button style ===*/
.pro-button {
  margin: 0;
  height: auto;
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}

/* button styling */
.pro-button {
  --border-right: 6px;
  --text-stroke-color: rgba(255, 255, 255, 0.6);
  --animation-color: #55198B;
  --fs-size: 1em;
  letter-spacing: 3px;
  text-decoration: none;
  font-size: var(--fs-size);
  font-family: "Arial";
  position: relative;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px var(--text-stroke-color);
}

/* this is the text, when you hover on button */
.hover-text {
  position: absolute;
  box-sizing: border-box;
  content: attr(data-text);
  color: var(--animation-color);
  width: 0%;
  inset: 0;
  border-right: var(--border-right) solid var(--animation-color);
  overflow: hidden;
  transition: 0.5s;
  -webkit-text-stroke: 1px var(--animation-color);
}

/* hover */
.pro-button:hover .hover-text {
  width: 100%;
  filter: drop-shadow(0 0 23px var(--animation-color))
}

/* code button */

.Btn {
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: .4s;
  cursor: pointer;
  position: relative;
  background-color: rgb(31, 31, 31);
  overflow: hidden;
}

.svgIcon {
  transition-duration: .3s;
}

.svgIcon path {
  fill: white;
}

.text {
  position: absolute;
  color: rgb(255, 255, 255);
  width: 120px;
  font-weight: 600;
  opacity: 0;
  transition-duration: .4s;
}

.Btn:hover {
  width: 110px;
  transition-duration: .4s;
  border-radius: 30px;
}

.Btn:hover .text {
  opacity: 1;
  transition-duration: .4s;
}

.Btn:hover .svgIcon {
  opacity: 0;
  transition-duration: .3s;
}

/* tech-button */

.btn-tech {
  --border-color: linear-gradient(-45deg, #ffae00, #7e03aa, #00fffb);
  --border-width: 0.125em;
  --curve-size: 0.5em;
  --blur: 30px;
  --bg: #080312;
  --color: #afffff;
  color: var(--color);
  cursor: pointer;
  /* use position: relative; so that BG is only for .btn-tech */
  position: relative;
  isolation: isolate;
  display: inline-grid;
  place-content: center;
  padding: 0.5em 1.5em;
  font-size: 17px;
  border: 0;
  text-transform: uppercase;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.6);
  clip-path: polygon(
      /* Top-left */
      0% var(--curve-size),
      var(--curve-size) 0,
      /* top-right */
      100% 0,
      100% calc(100% - var(--curve-size)),
      /* bottom-right 1 */
      calc(100% - var(--curve-size)) 100%,
      /* bottom-right 2 */
      0 100%);
  transition: color 250ms;
}

.btn-tech::after,
.btn-tech::before {
  content: "";
  position: absolute;
  inset: 0;
}

.btn-tech::before {
  background: var(--border-color);
  background-size: 300% 300%;
  animation: move-bg7234 5s ease infinite;
  z-index: -2;
}

@keyframes move-bg7234 {
  0% {
    background-position: 31% 0%;
  }

  50% {
    background-position: 70% 100%;
  }

  100% {
    background-position: 31% 0%;
  }
}

.btn-tech::after {
  background: var(--bg);
  z-index: -1;
  clip-path: polygon(
      /* Top-left */
      var(--border-width) calc(var(--curve-size) + var(--border-width) * 0.5),
      calc(var(--curve-size) + var(--border-width) * 0.5) var(--border-width),
      /* top-right */
      calc(100% - var(--border-width)) var(--border-width),
      calc(100% - var(--border-width)) calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)),
      /* bottom-right 1 */
      calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)) calc(100% - var(--border-width)),
      /* bottom-right 2 */
      var(--border-width) calc(100% - var(--border-width)));
  transition: clip-path 500ms;
}

.btn-tech:where(:hover, :focus)::after {
  clip-path: polygon(
      /* Top-left */
      calc(100% - var(--border-width)) calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)),
      calc(100% - var(--border-width)) var(--border-width),
      /* top-right */
      calc(100% - var(--border-width)) var(--border-width),
      calc(100% - var(--border-width)) calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)),
      /* bottom-right 1 */
      calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)) calc(100% - var(--border-width)),
      /* bottom-right 2 */
      calc(100% - calc(var(--curve-size) + var(--border-width) * 0.5)) calc(100% - var(--border-width)));
  transition: 200ms;
}

.btn-tech:where(:hover, :focus) {
  color: #fff;
}