// @import "../../_globalColor";/

// .contact-div-main {
//   display: flex;
// }

// // .dark-mode a {
// //   color: $textColorDark;
// // }

// .contact-div-main > * {
//   flex: 1;
// }

// .contact-title {
//   font-size: 65px;
//   font-weight: 400;
// }

// .contact-detail,
// .contact-detail-email {
//   margin-top: 20px;
//   font-size: 40px;
//   font-weight: 400;
//   color: $subTitle;
//   text-decoration: none;
// }

// .contact-detail:hover,
// .contact-detail-email:hover {
//   color: $textColor;
//   text-shadow: 2px 1px 2px $contactDetailHoverTS;
//   transition: all 0.3s;
// }

// .contact-subtitle {
//   text-transform: uppercase;
// }

// .contact-text-div {
//   margin-top: 1.5rem;
// }

.contact-margin-top {
  margin-top: 4rem;
  background-image: url("https://static.wixstatic.com/media/c837a6_46abf0a8171f47748472c8ecf2b19363~mv2.jpg/v1/fill/w_1903,h_945,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/c837a6_46abf0a8171f47748472c8ecf2b19363~mv2.jpg");
}

// .contact-image-div > img {
//   max-width: 50%;
//   height: auto;
//   // margin-left: 1.5rem;
//   margin-top: -4rem;
// }

// /* Media Query */
// @media (max-width: 1380px), (max-width: 768px) {
//   .contact-title {
//     font-size: 56px;
//     font-weight: 400;
//     text-align: center;
//   }

//   .contact-title {
//     font-size: 40px;
//     text-align: center;
//   }

//   .contact-subtitle {
//     font-size: 16px;
//     line-height: normal;
//     text-align: center;
//   }

//   .contact-text-div {
//     text-align: center;
//     margin-top: 1.5rem;
//   }

//   .contact-detail,
//   .contact-detail-email {
//     font-size: 20px;
//   }

//   .contact-image-div {
//     display: none;
//   }
// }


.form {
  display: flex;
  flex-direction: column;
  align-self: center;
  font-family: inherit;
  gap: 10px;
  padding-inline: 2em;
  padding-bottom: 0.4em;
  background-color: #171717;
  //background-color: #0a192f;
  border-radius: 20px;
}

.form-heading {
  text-align: center;
  margin: 1em;
  color: #64ffda;
  font-size: clamp(14px, 3vw, 24px);
  font-weight: 600;
  background-color: transparent;
  align-self: center;
}

.form-field {
  display: flex;
  // align-items: center;
  // justify-content: center;
  gap: 0.5em;
  border-radius: 10px;
  padding: 0.6em;
  border: none;
  outline: none;
  color: white;
  background-color: #171717;
  box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
}

.input-field {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: #ccd6f6;
  font-size: 20pt;
  // padding-inline: 1em;
}

.form-card1 {
  background-image: linear-gradient(163deg, #64ffda 0%, #64ffda 100%);
  border-radius: 22px;
  transition: all 0.3s;
      position: static;
      display: grid;
      height: auto;
      min-height: auto;
      grid-template-rows: min-content 1fr;
      grid-template-columns: 100%;
}

.form-card1:hover {
  box-shadow: 0px 0px 30px 1px rgba(100, 255, 218, 0.3);
}

.form-card2 {
  border-radius: 0;
  transition: all 0.2s;
}

.form-card2:hover {
  transform: scale(0.98);
  border-radius: 20px;
}

.button {
  background-image: linear-gradient(to right bottom, #e300ff, #ff00aa, #ff5956, #ffb900, #fffe00);
  border: none;
  font-size: 1.2em;
  border-radius: 1.5em;
  padding: 4px;
  margin: 16px 0px;
  transition: border-top-left-radius 0.2s ease-in,
    border-top-right-radius 0.2s ease-in 0.15s,
    border-bottom-right-radius 0.2s ease-in 0.3s,
    border-bottom-left-radius 0.2s ease-in 0.45s,
    padding 0.2s ease-in;
  position: relative;
}

.button__int {
  background-color: #212121;
  text-align: center;
  color: white;
  border-radius: 1.3em;
  padding: 10px 0px;
  transition: all 0.2s ease-in,
    border-top-left-radius 0.2s ease-in,
    border-top-right-radius 0.2s ease-in 0.15s,
    border-bottom-right-radius 0.2s ease-in 0.3s,
    border-bottom-left-radius 0.2s ease-in 0.45s,
    padding 0.2s ease-in;
  font-weight: 600;
  z-index: -1;
  box-shadow: -15px -10px 30px -5px rgba(225, 0, 255, 0.8),
    15px -10px 30px -5px rgba(255, 0, 212, 0.8),
    15px 10px 30px -5px rgba(255, 174, 0, 0.8),
    -15px 10px 30px -5px rgba(255, 230, 0.8);
}

.button:active .button__int {
  padding: 10px 30px;
}

.button:hover {
  border-radius: 0;
}

.button:hover .button__int {
  border-radius: 0;
}

.button:hover .button__int {
  box-shadow: -25px -10px 30px -5px rgba(225, 0, 255, 0.7),
    25px -10px 30px -5px rgba(255, 0, 212, 0.7),
    25px 10px 30px -5px rgba(255, 174, 0, 0.7),
    -25px 10px 30px -5px rgba(255, 230, 0, 0.7);
}