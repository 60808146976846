.container-cert {
    // background-color: white;
    display: flex;
    flex-direction: column;
    margin: 2.5rem 0;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.grid-container-cert {
    width: 95vw;
    max-width: 80vw;
    // border: 1px solid #ddd;
    // background-color: white;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 0;
    animation: 0.4s ease-in-out;
}

@media (min-width: 768px) {
    .grid-container-cert {
        grid-template-columns: repeat(2, 1fr);
    }
}

.card-cert {
    background: rgba(255, 255, 255, 0.54);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border: 1px solid rgba(255, 255, 255, 0.47);
    display: flex;
    align-items: start;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    background-color: #023047;
}

@media (min-width: 768px) {
    .card-cert {
        padding: 2.5rem;
        padding-bottom: 0.75rem;
    }
}

.card-number-cert {
    color: white;
    font-size: 30px;
    font-weight: 800;
}

.card-content-cert {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    justify-content: center;
}

.card-image-container-cert {
    height: 150px;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 200ms;
}

.card-image-container-cert:hover {
    transform: translateY(-10px);
}

@media (min-width: 768px) {
    .card-image-container-cert {
        height: 300px;
    }
}

.card-image-cert {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
}

.card-text-cert {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
}

.card-title-cert {
    font-size: 1.125rem;
    font-weight: 600;
    color: white;
}

.card-description-cert {
    font-size: 1rem;
    color: white;
}

.modal-cert {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content-cert {
    position: relative;
}

.modal-close-cert {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    color: black;
    font-size: 3rem;
    cursor: pointer;
}

.modal-image-cert {
    max-height: 90vh;
    max-width: 90vw;
    border-radius: 12px;
}