// @import "../../_globalColor";

// .social-media-div {
//   font-size: 2em;
// }

// .icon-button {
//   margin-bottom: 10px;
// }

// .icon-button i {
//   color: $textColorDark;
//   border-radius: 2.6rem;
//   cursor: pointer;
//   display: inline-block;
//   font-size: 1.3rem;
//   height: 2.6rem;
//   line-height: 2.6rem;
//   margin: 0 5px;
//   position: relative;
//   text-align: center;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   width: 2.6rem;
//   margin-bottom: 10px;
//   transition: 0.2s ease-in;
// }

// .facebook i {
//   background-color: $faceBook;
// }

// .linkedin i {
//   background-color: $linkedin;
// }

// .github i {
//   background-color: $github;
// }

// .gitlab i {
//   background-color: $gitlab;
// }

// .google i {
//   background-color: $google;
// }

// .twitter i {
//   background-color: $twitter;
// }

// .medium i {
//   background-color: $medium;
// }

// .stack-overflow i {
//   background-color: $stackoverflow;
// }

// .kaggle i {
//   background-color: $kaggle;
// }

// .instagram i {
//   background-color: $instagram;
// }

// .outlook i {
//   background-color: $outlook;
// }


// /* Hover Transition */
// .twitter i:hover,
// .google i:hover,
// .gitlab i:hover,
// .github i:hover,
// .linkedin i:hover,
// .facebook i:hover,
// .instagram i:hover,
// .stack-overflow i:hover,
// .kaggle i:hover,
// .outlook i:hover,
// .medium i:hover {
//   background-color: $textColor;
// }

/* Media Query */
// @media (max-width: 768px) {
//   .social-media-div {
//     text-align: center;
//   }
// }


.main_back {
  position: absolute;
  border-radius: 10px;
  transform: rotate(90deg);
  width: 11em;
  height: 11em;
  background: linear-gradient(270deg, #03a9f4, #cc39a4, #ffb5d2);
  z-index: -2;
  box-shadow: inset 0px 0px 180px 5px #ffffff;
}

.mainsocial-container {
  display: flex;
  flex-wrap: wrap;
  width: 14em;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  align-items: center;
  justify-content: center;
  z-index: -1;
  transition: transform 0.3s ease-in-out;
}

.quant-card {
  width: 60px;
  height: 60px;
  border-top-left-radius: 10px;
  // background: lightgrey;
  transition: 0.4s ease-in-out, 0.2s background-color ease-in-out,
    0.2s background-image ease-in-out;
  // background: rgba(255, 255, 255, 0.596);
  backdrop-filter: blur(5px);
  border: 1px solid transparent;
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.quant-card .instagram {
  opacity: 0;
  transition: 0.2s ease-in-out;
  fill: #cc39a4;
}

.quant-card:nth-child(2) {
  border-radius: 0px;
}

.quant-card:nth-child(2) .twitter {
  opacity: 0;
  transition: 0.2s ease-in-out;
  fill: #03a9f4;
}

.quant-card:nth-child(3) {
  border-top-right-radius: 10px;
  border-top-left-radius: 0px;
}

.quant-card:nth-child(3) .medium {
  opacity: 0;
  transition: 0.2s ease-in-out;
  // fill: black;
}

.quant-card:nth-child(4) {
  border-radius: 0px;
}

.quant-card:nth-child(4) .gitlab {
  opacity: 0;
  transition: 0.2s ease-in-out;
  fill: black;
}

.quant-card:nth-child(5) {
  border-radius: 0px;
}

.quant-card:nth-child(5) .linkedin {
  // position: absolute;
  margin-left: 0.2em;
  margin-top: 0.2em;
  text-align: center;
  opacity: 0;
  transition: 0.2s ease-in-out;
}

.quant-card:nth-child(6) {
  border-radius: 0px;
}

.quant-card:nth-child(6) .gmail {
  opacity: 0;
  transition: 0.2s ease-in-out;
  fill: #8c9eff;
}

.quant-card:nth-child(7) {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 0px;
}

.quant-card:nth-child(7) .github {
  opacity: 0;
  transition: 0.2s ease-in-out;
  fill: black;
}

.quant-card:nth-child(8) {
  border-radius: 0px;
}

.quant-card:nth-child(8) .outlook {
  opacity: 0;
  transition: 0.2s ease-in-out;
  fill: #29b6f6;
}

.quant-card:nth-child(9) {
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0px;
}

.quant-card:nth-child(9) .reddit {
  opacity: 0;
  transition: 0.2s ease-in-out;
}

.mainsocial-container:hover {
  width: 14em;
  cursor: pointer;
  transform: rotate(-45deg);
}

.mainsocial-container:hover .main_back {
  opacity: 0;
}

.mainsocial-container:hover .quant-card {
  margin: 0.2em;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.2);
}

.mainsocial-container:hover .quant-card svg {
  transform: rotate(45deg);
}

.mainsocial-container:hover .quant-card:nth-child(5) {
  border: transparent;
}

.mainsocial-container:hover .text-social {
  opacity: 0;
  z-index: -3;
}

.mainsocial-container:hover .instagram {
  opacity: 1;
}

.mainsocial-container:hover .twitter {
  opacity: 1;
}

.mainsocial-container:hover .medium {
  opacity: 1;
}

.mainsocial-container:hover .gitlab {
  opacity: 1;
}

.mainsocial-container:hover .linkedin {
  opacity: 1;
}

.mainsocial-container:hover .gmail {
  opacity: 1;
}

.mainsocial-container:hover .github {
  opacity: 1;
}

.mainsocial-container:hover .outlook {
  opacity: 1;
}

.mainsocial-container:hover .reddit {
  opacity: 1;
}

.quant-card:nth-child(1):hover {
  background-color: #cc39a4;
}

.quant-card:nth-child(1):hover .instagram {
  fill: white;
}

.quant-card:nth-child(2):hover {
  background-color: #03a9f4;
}

.quant-card:nth-child(2):hover .twitter {
  fill: white;
}

.quant-card:nth-child(3):hover {
  background-color: black;
}

.quant-card:nth-child(3):hover .medium {
  fill: white;
}

.quant-card:nth-child(4):hover {
  background-color: #ffb74d;
}

.quant-card:nth-child(4):hover .gitlab {
  fill: white;
}

.quant-card:nth-child(5):hover {
  // animation: backgroundIMG 0.1s;
  background-color: #0073b1;
  animation-fill-mode: forwards;
}

.quant-card:nth-child(5):hover .linkedin #paint0_linear_501_142 stop {
  stop-color: white;
}

.quant-card:nth-child(5):hover .linkedin #paint1_linear_501_142 stop {
  stop-color: white;
}

.quant-card:nth-child(5):hover .linkedin #paint2_linear_501_142 stop {
  stop-color: white;
}

// @keyframes backgroundIMG {
//   100% {
//     background-image: linear-gradient(#bf66ff, #6248ff, #00ddeb);
//   }
// }

.quant-card:nth-child(6):hover {
  background-color: #90caf9;
}

.quant-card:nth-child(6):hover .gmail {
  fill: white;
}

.quant-card:nth-child(7):hover {
  background-color: black;
}

.quant-card:nth-child(7):hover .github {
  fill: white;
}

.quant-card:nth-child(8):hover {
  fill: #ffffff;
  background-color: #a2d2ff;
}

.quant-card:nth-child(8):hover .outlook>g path:nth-of-type(1) {
  fill: white;
}

.quant-card:nth-child(8):hover .outlook>g path:nth-of-type(2) {
  fill: white;
}

.quant-card:nth-child(8):hover .outlook>g path:nth-of-type(3) {
  fill: white;
}

.quant-card:nth-child(9):hover {
  background-color: rgb(255, 69, 0);
}

.quant-card:nth-child(9) .reddit>g circle {
  fill: rgb(255, 69, 0);
}

.quant-card:nth-child(9) .reddit>g path {
  fill: white;
}

.text-social {
  position: absolute;
  font-size: 0.7em;
  transition: 0.4s ease-in-out;
  color: black;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.33em;
  z-index: 3;
}