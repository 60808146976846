.container {
    display: flex;
    flex-direction: column;
    margin: 2.5rem auto;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.section-contain {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.work-exp-card {
    width: 30vw;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
    align-items: start;
    height: fit-content;
    min-width: clamp(350px, 10vw ,450px);
    border-radius: 32px;
    box-shadow: 0 4px 6px rgba(85, 25, 139, 0.5);
    margin: 40px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.work-exp-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(85, 25, 139, 0.7);
    /* Updated shadow color and intensity */
}

.image-section-work-exp {
    display: flex;
    width: 100px;
    height: 100px;
}

.image-section-work-exp img {
    width: 100%;
    height: 100%;
}

.work-exp-title {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: start;
    text-align: start;
}

.work-exp-title h2 {
    font-size: clamp(12px, 10vw, 24px);
    font-weight: bold;
}

.work-exp-title p {
    font-size: clamp(8px, 2.5vw, 20px);
    font-weight: 500;
    text-align: justify;
}


.work-exp-description {
    display: block;
    align-items: start;
    text-align: start;
    text-align: justify;
    color: #55198b;
}

.work-exp-description p {
font-size: 16px;
}

// @media (max-width: 768px) {
//     .work-exp-card {
//         width: 10vw;
//         height: 600px;
//         padding: 20px;
//         margin: 10px;
//         grid-template-columns: repeat(1, 1fr);
//     }

//     .image-section-work-exp {
//         width: 80px;
//         height: 80px;
//     }

//     .work-exp-title h2 {
//         font-size: clamp(12px, 3vw, 18px);
//     }

//     .work-exp-title p {
//         font-size: clamp(8px, 2.5vw, 16px);
//     }

//     .work-exp-description p {
//         font-size: 14px;
//     }
// }