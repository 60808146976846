@import "../../_globalColor";

.skills-main-div {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.skills-text-div {
  margin-left: 50px;
}

.skills-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}

.skills-image-div > img {
  max-width: 100%;
  height: auto;
}

.skills-heading {
  text-align: center;
  padding: 24px;
  font-size: 48px;
  font-weight: 900;
  // color: #55198b;
}
.subTitle {
  color: $subTitle;
}

.skills-text {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 2;
  align-items: center;
  justify-content: start;
}

/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 48px;
    font-weight: 900;
  }
}
@media (max-width: 768px) {
  .skills-heading {
    font-size: 20px;
    font-weight: 900;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }
  .skills-main-div {
    flex-direction: column;
  }
  .skills-text-div {
    margin-left: 0px;
    margin: 20px;
  }
  .skills-text {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    gap: 1;
    align-items: center;
    justify-content: center;
  }
  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .skills-image-div {
    /* display: none; */
    order: 2;
  }
}
